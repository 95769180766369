import React from "react";
import { AppBar, Toolbar, Button, Typography, Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react"; // Import the Auth0 hook

const NavBar = () => {
  const { logout, getAccessTokenSilently } = useAuth0(); // Get the logout function from Auth0

  const openBullBoard = async () => {
    try {
      const token = await getAccessTokenSilently(); // Get the Auth0 token
      const bullBoardUrl = `/admin/queues?token=${token}`; // Append the token as a query param
      window.open(bullBoardUrl, "_blank");
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  };

  return (
    <AppBar position="static" 
    sx={{ backgroundColor: (theme) => theme.palette.primary.dark }} 
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Boom-Activation
          <Divider
            variant="fullWidth"
            sx={{
              borderTop: "1px solid pink", // Add thickness to the top border
              // borderBottom: "2px solid black", // Uncomment this line to add thickness to the bottom border
            }}
          />
          {/* create button for admin queues */}
          <Button
            color="inherit"
            onClick={openBullBoard}
            sx={{
              textDecoration: "none",
              color: "yellow",
            }}
          >
            Queues
          </Button>
        </Typography>

        <Button
          color="inherit"
          component={NavLink}
          to="/"
          sx={{
            textDecoration: "none",
            color: "white",
            "&.active": { color: "yellow" }, // Highlights when active
          }}
        >
          Home
        </Button>

        <Button
          color="inherit"
          component={NavLink}
          to="/upload"
          sx={{
            textDecoration: "none",
            color: "white",
            "&.active": { color: "yellow" }, // Highlights when active
          }}
        >
          Upload
        </Button>

        <Button
          color="inherit"
          component={NavLink}
          to="/tasks"
          sx={{
            textDecoration: "none",
            color: "white",
            "&.active": { color: "yellow" }, // Highlights when active
          }}
        >
          Tasks
        </Button>

        {/* Logout button */}
        <Button
          color="inherit"
          onClick={() =>
            logout({
              returnTo: window.location.origin, // Redirect to home or login page based on your needs
            })
          }
          sx={{
            textDecoration: "none",
            color: "white",
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
