import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CSVUpload from './components/CSVUpload';
import TaskList from './components/TaskList';
import NavBar from './components/NavBar';
import Title from './components/Title';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';

// Protected app wrapper to guard all components
const ProtectedApp = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return children; // Render the entire app if authenticated
};

const App = () => {
  return (
    <Router>
      <ProtectedApp>
        <NavBar />
        <Routes>
          <Route path="/" element={<Welcome title="Welcome" />} />
          <Route path="/upload" element={<CSVUpload title="Upload CSV"/>} />
          <Route path="/tasks" element={<TaskList title="Task List"/>} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </ProtectedApp>
    </Router>
  );
};

const Welcome = () => (
  <div>
    <Title pageTitle="Home" />
    <h1>Welcome to Boom-Activation</h1>
  </div>
);

export default App;
