import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Title from "./Title";

const TaskList = ({title}) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading
  const [actionLoading, setActionLoading] = useState(false); // Loading state for task actions
  const [error, setError] = useState(null);
  const [startingTaskId, setStartingTaskId] = useState(null); // For tracking start/stop actions
  const [deletingTaskId, setDeletingTaskId] = useState(null); // For tracking deletion actions
  const [openDialog, setOpenDialog] = useState(false); // Dialog state for confirmation
  const [dialogAction, setDialogAction] = useState(""); // Track the current action ('start', 'stop', 'delete')
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [downloadLoading, setDownloadLoading] = useState({
    taskId: null,
    status: null,
    loading: false,
  }); // Loading state for CSV download
  const { getAccessTokenSilently } = useAuth0();

  // Fetch the tasks from the server
  const fetchTasks = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get("/api/tasks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTasks(response.data);
    } catch (error) {
      setError("Failed to load tasks");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]); // Add getAccessTokenSilently as a dependency

  // Handle task start/stop toggle
  // const toggleTask = async (taskId, status) => {
  //   const newStatus = status === "in-progress" ? "stopped" : "in-progress";
  //   setActionLoading(true); // Start action loading
  //   setStartingTaskId(taskId);

  //   try {
  //     await axios.post(`/tasks/toggle/${taskId}`, { status: newStatus });
  //     fetchTasks(); // Refresh the task list after success
  //   } catch (error) {
  //     setError(`Failed to toggle task ${taskId}`);
  //   } finally {
  //     setStartingTaskId(null);
  //     setActionLoading(false); // Stop action loading
  //   }
  // };

  // Handle task deletion (open confirmation dialog)
  const handleDeleteTask = (taskId) => {
    setDeletingTaskId(taskId); // Set the task ID to be deleted
    setDialogAction("delete"); // Set the dialog action
    setOpenDialog(true); // Open the confirmation dialog
  };

  // Confirm deletion and delete the task from the server
  const confirmDeleteTask = async () => {
    setActionLoading(true); // Start action loading for delete

    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`/api/tasks/${deletingTaskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Send request to delete task and associated data
      fetchTasks(); // Refresh task list after deletion
    } catch (error) {
      setError("Failed to delete task");
    } finally {
      setOpenDialog(false); // Close the dialog
      setDeletingTaskId(null); // Reset deleting task ID
      setActionLoading(false); // Stop action loading for delete
    }
  };

  // Open confirmation dialog for starting/stopping a task
  const handleToggleTaskConfirmation = (taskId, action) => {
    setStartingTaskId(taskId);
    setDialogAction(action); // Track the current action (start/stop)
    setOpenDialog(true); // Open the confirmation dialog
  };

  // Confirm the start/stop action in the dialog
  const confirmToggleTask = async () => {
    setActionLoading(true); // Start action loading for toggle
    const task = tasks.find((task) => task._id === startingTaskId);
    const newStatus = task.status === "in-progress" ? "stopped" : "in-progress";

    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `/api/tasks/toggle/${startingTaskId}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchTasks(); // Refresh the task list
    } catch (error) {
      setError(`Failed to toggle task ${startingTaskId}`);
    } finally {
      setOpenDialog(false); // Close the dialog
      setStartingTaskId(null); // Reset starting task ID
      setActionLoading(false); // Stop action loading for toggle
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  // Handle CSV download for the task
  const handleDownloadCSV = async (taskId, status = "all") => {
    try {
      const token = await getAccessTokenSilently();
      // Set loading state for the download
      setDownloadLoading({ taskId, status, loading: true });
      const response = await axios.get(
        `/api/download-data/${taskId}?status=${status}`,
        {
          responseType: "blob", // Important to specify blob response type
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Create a link element and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Set the file name dynamically based on task ID and status
      link.setAttribute("download", `${taskId}_${status}_data.csv`);

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Reset the loading state after download
      setDownloadLoading({ taskId: null, status: null, loading: false });

      // Cleanup: Remove the link after triggering the download
      document.body.removeChild(link);
    } catch (error) {
      setDownloadLoading({ taskId: null, status: null, loading: false });
      console.error("Error downloading CSV:", error);
    }
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  if (loading) {
    return (
      <Container>
        <Typography variant="h5">Loading tasks...</Typography>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  // Paginate the tasks
  const paginatedTasks = tasks.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
    <Title pageTitle={title} />
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 2, backgroundColor:grey[100] }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Task List
        </Typography>

        {tasks.length === 0 ? (
          <Typography variant="h6">No tasks available</Typography>
        ) : (
          <>
            <TableContainer component={Paper} sx={{backgroundColor:blue[50]}}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "secondary.dark"}}>
                    <TableCell sx={{ color: "#ffffff" }}>Date</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Task Id</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Client Id</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Status</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Total</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Success</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Fail</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>
                      Start / Stop / Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedTasks.map((task) => (
                    <TableRow key={task._id}>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Created:
                        <br />
                        {moment(task.createdAt).format("DD/MM/YYYY")}{" "}
                        {moment(task.createdAt).format("hh:mm:ss A")}
                        <br />
                        <br />
                        Updated:
                        <br />
                        {moment(task.updatedAt).format("DD/MM/YYYY")}{" "}
                        {moment(task.updatedAt).format("hh:mm:ss A")}
                      </TableCell>

                      <TableCell>{task._id}</TableCell>
                      <TableCell>{task.name}</TableCell>
                      <TableCell>{task.status}</TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleDownloadCSV(task._id, "all")}
                        >
                          {downloadLoading.taskId === task._id &&
                          downloadLoading.status === "all" ? (
                            <CircularProgress size={24} />
                          ) : (
                            task.totalRows
                          )}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleDownloadCSV(task._id, "success")}
                        >
                          {downloadLoading.taskId === task._id &&
                          downloadLoading.status === "success" ? (
                            <CircularProgress size={24} />
                          ) : (
                            task.successProcessed
                          )}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleDownloadCSV(task._id, "failed")}
                        >
                          {downloadLoading.taskId === task._id &&
                          downloadLoading.status === "failed" ? (
                            <CircularProgress size={24} />
                          ) : (
                            task.failedProcessed
                          )}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: 2,
                          }}
                        >
                          {/* Start/Stop Task */}
                          {actionLoading && startingTaskId === task._id ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Button
                              variant="contained"
                              color={
                                task.status === "in-progress"
                                  ? "secondary"
                                  : "primary"
                              }
                              onClick={() =>
                                handleToggleTaskConfirmation(
                                  task._id,
                                  task.status === "in-progress"
                                    ? "stop"
                                    : "start"
                                )
                              }
                              disabled={task.status === "pending"}
                            >
                              {task.status === "in-progress" ? "Stop" : "Start"}
                            </Button>
                          )}

                          {/* Delete Task */}
                          {actionLoading && deletingTaskId === task._id ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleDeleteTask(task._id)}
                              disabled={
                                task.status === "in-progress" ||
                                task.status === "pending"
                              }
                            >
                              Delete
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination controls */}
            <TablePagination
              component="div"
              count={tasks.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>

      {/* Confirmation Dialog for Start/Stop or Deletion */}
      <Dialog
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") setOpenDialog(false);
        }}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          {dialogAction === "delete" ? "Delete Task" : "Start/Stop Task"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogAction === "delete"
              ? "Are you sure you want to delete this task? This will remove all related activation data."
              : `Are you sure you want to ${
                  dialogAction === "start" ? "start" : "stop"
                } this task?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            disabled={actionLoading}
          >
            Cancel
          </Button>
          {actionLoading && dialogAction === "delete" ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              onClick={
                dialogAction === "delete"
                  ? confirmDeleteTask
                  : confirmToggleTask
              }
              color={dialogAction === "delete" ? "error" : "primary"}
              disabled={actionLoading} // Disable button while loading
            >
              {dialogAction === "delete" ? "Delete" : "Confirm"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
    </>
  );
};

export default TaskList;
