import React, { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { Container, Typography, Box, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, LinearProgress, Tooltip } from '@mui/material';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from "@auth0/auth0-react";
import { grey } from '@mui/material/colors';
import Title from './Title';

const CSVUpload = ({title}) => {
  const requiredHeaders = [
    { header: 'IMEI', label: 'IMEI' },
    { header: 'serialNumber', label: 'Serial Number' },
    { header: 'firstName', label: 'First Name' },
    { header: 'lastName', label: 'Last Name' },
    { header: 'address', label: 'Address' },
    { header: 'city', label: 'City' },
    { header: 'state', label: 'State' },
    { header: 'zip', label: 'Zip' },
  ];

  const [csvData, setCsvData] = useState([]);
  const [finishLabel, setFinishLabel] = useState('Finish');
  const chunkSize = 1000;
  const [taskId] = useState(uuidv4());
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(taskId).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      },
      (err) => {
        console.error('Failed to copy text to clipboard', err);
      }
    );
  };

  const uploadCSVChunk = async (chunk, chunkIndex, totalChunks) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        '/api/upload-csv',
        {
          taskId,
          chunk,
          chunkIndex,
          totalChunks,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(`Chunk ${chunkIndex + 1} uploaded successfully:`, response.data);
      setProgress(((chunkIndex + 1) / totalChunks) * 100);
    } catch (error) {
      console.error(`Error uploading chunk ${chunkIndex + 1}:`, error);
      throw error;
    }
  };

  const uploadCSVDataInChunks = async () => {
    if (!csvData.length) return;

    const totalChunks = Math.ceil(csvData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const chunk = csvData.slice(i * chunkSize, (i + 1) * chunkSize);
      console.log(`Uploading chunk ${i + 1} of ${totalChunks}`);
      await uploadCSVChunk(chunk, i, totalChunks);
    }

    setFinishLabel('Done!');
    console.log('All chunks uploaded successfully');
  };

  return (
    <>
    <Title pageTitle={title} />
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4, backgroundColor:grey[100] }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Upload CSV Data
        </Typography>

        <Box sx={{ marginTop: 3 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Import and Upload CSV Data
          </Typography>

          <Importer
            dataHandler={async (rows) => {
              console.log('Parsed CSV rows:', rows);
              setCsvData((prevData) => [...prevData, ...rows]);
            }}
            chunkSize={50000}
            defaultNoHeader={false}
            restartable={false}
            onComplete={() => {
              console.log('CSV import complete');
            }}
            onClose={async () => {
              setFinishLabel('Processing...');
              setProgress(0);
              await uploadCSVDataInChunks();
            }}
          >
            {requiredHeaders.map((header) => (
              <ImporterField key={header.header} name={header.header} label={header.label} />
            ))}
          </Importer>
        </Box>

        {progress > 0 && (
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <Typography variant="body2">
              {progress !== 100 ? 'Uploading: ' : 'Upload Completed: '}
              {Math.round(progress)}%
            </Typography>
            <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5 }} />
          </Box>
        )}

        {progress > 0 && (
          <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            <Tooltip title={copySuccess ? 'Copied!' : 'Click to copy Task ID'}>
              <Button variant="contained" onClick={copyToClipboard}>
                {`Task Id: ${taskId}, Status: ${finishLabel}`}
              </Button>
            </Tooltip>
          </Box>
        )}

        {csvData.length > 0 && (
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="h6">CSV Data Preview (First 5 Records)</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {requiredHeaders.map((header) => (
                      <TableCell key={header.header}>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {csvData.slice(0, 5).map((row, index) => (
                    <TableRow key={index}>
                      {requiredHeaders.map((header) => (
                        <TableCell key={header.header}>{row[header.header]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
    </Container>
    </>
  );
};

export default CSVUpload;
